import React from "react";

import GenreLayout from "./GenreLayout";
import ZoomControls from "./ZoomControls";
import "./GenreMap.css";
import instruments from "./images/map7-dark.jpeg";

class GenreMap extends React.Component {
  constructor(props) {  
    super(props);
    this.viewportRef = React.createRef();
    this.zoomSpeed = 0.5;
    this.naturalMapSize = 1200;
    this.state = {
      viewport: {
        marginLeft: 0,
        marginTop: 0,
        width: 0,
        height: 0
      },
      grabStartPos: {
        x: 0,
        y: 0
      },
      grabStartMargin: {
        left: 0,
        top: 0
      },
      grabbing: false,
      dragging: false,
      scaling: false,
      scaleDistance: 0,
      zoom: 1
    };
  }

  mapSize() {
    return this.naturalMapSize * this.state.zoom;
  }

  resizeMap(toSize) {
    const targetZoom = toSize / this.naturalMapSize;
    this.zoomTo(targetZoom, true);
  }

  ensureMinimumMapSize() {
    const viewportWidth = this.viewportRef.current.clientWidth;
    const viewportHeight = this.viewportRef.current.clientHeight;
    const mapSize = this.mapSize();

    if (Math.max(viewportWidth, viewportHeight) > mapSize) {
      this.resizeMap(Math.max(viewportWidth, viewportHeight))
    }
  }

  componentDidMount() {
    const viewportWidth = this.viewportRef.current.clientWidth;
    const viewportHeight = this.viewportRef.current.clientHeight;
    const mapSize = this.mapSize();

    this.setState({
      viewport: {
        ...this.state.viewport,
        width: viewportWidth,
        height: viewportHeight,
        marginLeft: -(mapSize - viewportWidth) / 2,
        marginTop: -(mapSize - viewportHeight) / 2,
      },
    }, this.ensureMinimumMapSize())

    this.viewportResizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        console.log("resize", entry.contentRect.width, entry.contentRect.height);
        const prevWidth = this.state.viewport.width;
        const prevHeight = this.state.viewport.height;
        const newWidth = entry.contentRect.width;
        const newHeight = entry.contentRect.height;
        const widthChangePercent = Math.abs((newWidth - prevWidth) / prevWidth);
        const heightChangePercent = Math.abs((newHeight - prevHeight) / prevHeight);
        const changePercent = Math.max(widthChangePercent, heightChangePercent);

        if (changePercent > 0.02) {
          this.setState({
            viewport: {
              ...this.state.viewport,
              width: entry.contentRect.width,
              height: entry.contentRect.height
            },
          }, () => {
            this.resizeMap(Math.max(entry.contentRect.width, entry.contentRect.height))
          });
        }
      }
    });

    this.viewportResizeObserver.observe(this.viewportRef.current);

    this.viewportRef.current.addEventListener("wheel", this.handleMouseWheel, { passive: false });
    this.viewportRef.current.addEventListener("touchstart", this.handleTouchStart, { passive: false });
    this.viewportRef.current.addEventListener("touchmove", this.handleTouchMove, { passive: false });
    this.viewportRef.current.addEventListener("touchend", this.handleTouchEnd, { passive: false });
  }

  componentWillUnmount() {
    this.viewportResizeObserver.disconnect();
  }

  grabStart(x, y) {
    this.setState({
      grabStartPos: {
        ...this.state.grabStartPos,
        x: x,
        y: y
      },
      grabStartMargin: {
        ...this.state.grabStartMargin,
        left: this.state.viewport.marginLeft,
        top: this.state.viewport.marginTop
      },
      grabbing: true
    });
  }

  handleMouseDown = (e) => {
    e.preventDefault();
    this.grabStart(e.clientX, e.clientY);
  }

  handleTouchStart = (e) => {
    e.preventDefault();
    if(e.touches.length > 1) {
      const dx = e.touches[0].clientX - e.touches[1].clientX;
      const dy = e.touches[0].clientY - e.touches[1].clientY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      this.setState({
        scaling: true,
        scaleDistance: distance
      })
      return;
    }
    this.grabStart(e.touches[0].clientX, e.touches[0].clientY);
  }

  grabMove(x, y) {
    if (!this.state.grabbing) {
      return;
    }

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    const dx = x - this.state.grabStartPos.x;
    const dy = y - this.state.grabStartPos.y;
    if(Math.abs(dx) > 10 || Math.abs(dy) > 10) {
      this.setState({
        dragging: true
      })
    }
    let newMarginLeft = this.state.grabStartMargin.left + dx;
    let newMarginTop = this.state.grabStartMargin.top + dy;
    newMarginLeft = clamp(newMarginLeft, -(this.mapSize() - this.state.viewport.width), 0);
    newMarginTop = clamp(newMarginTop, -(this.mapSize() - this.state.viewport.height), 0);

    this.setState({
      viewport: {
        ...this.state.viewport,
        marginLeft: newMarginLeft,
        marginTop: newMarginTop
      }
    });
  }

  handleMouseMove = (e) => {
    e.preventDefault();
    this.grabMove(e.clientX, e.clientY);
  }

  handleTouchMove = (e) => {
    e.preventDefault();
    if(e.touches.length > 1 && this.state.scaling) {
      const dx = e.touches[0].clientX - e.touches[1].clientX;
      const dy = e.touches[0].clientY - e.touches[1].clientY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const diff = distance - this.state.scaleDistance;
      const diffDirection = diff > 0 ? 1 : -1;
      const newZoom = this.state.zoom * (1 + diff * 0.008);
      this.zoomTo(newZoom);
      this.setState({
        scaleDistance: distance
      })
      return;
    }
    this.grabMove(e.touches[0].clientX, e.touches[0].clientY);
  }

  handleTouchEnd = (e) => {
    e.preventDefault();
    this.setState({
      scaling: false
    })
    if (this.state.grabbing) {
      this.stopGrabbing();
    }
  }

  handleMouseUp = (e) => {
    e.preventDefault();
    if (this.state.grabbing) {
      this.stopGrabbing();
    }
  }

  handleMouseLeave = (e) => {
    e.preventDefault();
    if (this.state.grabbing) {
      this.stopGrabbing();
    }
  }

  stopGrabbing = () => {
    this.setState({
      dragging: false,
      grabbing: false
    });
  }

  handleMouseWheel = (e) => {
    e.preventDefault();
    const direction = e.deltaY > 0 ? -1 : 1;
    const newZoom = this.state.zoom * (1 + direction * 0.02);
    this.zoomTo(newZoom);
  }

  zoomTo = (newZoom, andRecenter) => {
    if (typeof andRecenter === undefined) {
      andRecenter = false
    }
    const minZoom = Math.max(this.state.viewport.width, this.state.viewport.height) / this.naturalMapSize;
    newZoom = Math.min(Math.max(newZoom, minZoom), 10);
    const prevZoom = this.state.zoom;
    const zoomRatio = newZoom / prevZoom;
    const viewportWidth = this.state.viewport.width;
    const viewportHeight = this.state.viewport.height;
    const marginLeft = this.state.viewport.marginLeft;
    const marginTop = this.state.viewport.marginTop;
    const newMapSize = this.naturalMapSize * newZoom; 
    let newMarginLeft = 0;
    let newMarginTop = 0;
    if (andRecenter) {
      newMarginLeft = -(newMapSize - viewportWidth) / 2
      newMarginTop = -(newMapSize - viewportHeight) / 2
    } else {
      newMarginLeft = marginLeft * zoomRatio - (viewportWidth * (zoomRatio - 1) / 2);
      newMarginTop = marginTop * zoomRatio - (viewportHeight * (zoomRatio - 1) / 2);
    }

    this.setState({
      viewport: {
        ...this.state.viewport,
        marginLeft: newMarginLeft,
        marginTop: newMarginTop
      },
      zoom: newZoom
    });
  }

  zoomIn = () => {
    const prevZoom = this.state.zoom;
    const newZoom = prevZoom + this.zoomSpeed;

    this.zoomTo(newZoom)
  }

  zoomOut = () => {
    const prevZoom = this.state.zoom;
    const newZoom = prevZoom - this.zoomSpeed;

    this.zoomTo(newZoom)
  }

  render() {
    const viewportStyle = {};
    if (this.state.dragging) {
      viewportStyle.cursor = 'move'
    } else if (this.state.grabbing) {
      viewportStyle.cursor = 'grabbing'
      viewportStyle.userSelect = 'none';
    } else {
      viewportStyle.cursor = 'grab'
    }
    return (
      <div className="GenreMap__container">
        <div
          className="GenreMap__viewport"
          ref={this.viewportRef}
          style={viewportStyle}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          onMouseLeave={this.handleMouseLeave}
        >
          <ZoomControls
            zoomIn={this.zoomIn}
            zoomOut={this.zoomOut}
          />
          <div
            style={{
              backgroundImage: `url(${instruments})`,
              marginLeft: `${this.state.viewport.marginLeft || 0}px`,
              marginTop: `${this.state.viewport.marginTop || 0}px`,
              width: `${this.mapSize()}px`,
              height: `${this.mapSize()}px`
            }}
            className="GenreMap"
          >
            <GenreLayout
              mapIsDraggingOrScaling={this.state.dragging || this.state.scaling}
              zoom={this.state.zoom}
              genre={this.props.genre}
              genreSelected={this.props.genreSelected}
              dimensions={
                {
                  width: this.mapSize(),
                  height: this.mapSize()
                }
              }
            /> 
          </div>
        </div>
      </div>
    );
  }
}

export default GenreMap;