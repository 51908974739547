import React from "react";

import "./Intro.css";
import "./loader.css";
import logo from "./images/logo-no-background.svg";

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let loading = null;
    if (this.props.loading) {
      loading = (
        <div className="Intro__loader">
          <div className="loader loader--small"/>
        </div>
      );
    }

    return (
      <div
        className="Intro"
      >
        <img
          className="Intro__logo"
          src={logo}
        />
        <div className="Intro__instructions">
          <p>
            Use the map to explore and select a genre
          </p>
        </div>
        { loading }
      </div>
    );
  }
}

export default Intro;