import React from "react";

import './GenreExplorer.css';
import GenreMap from "./GenreMap";
import Jukebox from "./Jukebox";


class GenreExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      dimensions: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      genre: {
        id: null
      }
    };
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.setState({
          dimensions: {
            ...this.state.dimensions,
            width: entry.contentRect.width,
            height: entry.contentRect.height
          }
        });
      }
    });

    this.resizeObserver.observe(this.ref.current);
  }

  genreSelected = (genreData) => {
    this.setState({
      genre: genreData
    })
  }

  render() {
    return (
      <div
        className="GenreExplorer"
        ref={this.ref}
      >
        <GenreMap
          genre={this.state.genre}
          genreSelected = {this.genreSelected}
        />
        <div className="GenreExplorer__sidebar">
          <Jukebox
            genre={this.state.genre}
            explorerDimensions={this.state.dimensions}
          />
        </div>
      </div>
    );
  }
}

export default GenreExplorer;
