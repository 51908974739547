import React from "react";

import "./SpotifyPlayer.css";
import "./loader.css";

class SpotifyPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const spotifyArtistId = this.props.artistId;
    let height = 380;
    let size = "large";
    if (this.props.explorerDimensions.width < 600) {
      height = 80;
      size = "small";
    }

    const player = this.props.loading ? (
      <div
        className="SpotifyPlayer__loading"
        style={{
          height: `${height + 3.5}px`
        }}
      >
        <div className={`loader loader--${size}`} />
      </div>
    ) : (
      <iframe
        className="SpotifyPlayer__embed"
        title="Spotify Web Player"
        src={`https://open.spotify.com/embed/artist/${spotifyArtistId}`}
        width="100%"
        height={height}
        frameBorder="0"
        marginHeight="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    );

    return (
      <div className="SpotifyPlayer">
        {player}
      </div>
    );
  }
}

export default SpotifyPlayer;