import React from "react";

import "./Jukebox.css";
import "./loader.css";
import SpotifyPlayer from "./SpotifyPlayer";
import getColors from "./palette";
import Intro from "./Intro";

class Jukebox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorScheme: {
        hex: "#000000",
        luminance: 200,
        complementaryHex: "#000000"
      },
      colors: [],
      spotifyArtistId: null,
      thumbnailUrl: null,
      loading: false,
      firstArtistLoaded: false
    };
    this.thumnailRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.genre.id != this.props.genre.id) {
      this.genreChanged();
    }
  }

  randomizeArtist = () => {
    this.setState({
      loading: true
    }, () => {
      fetch(
        `${process.env.REACT_APP_SERVICE_BASE_URL}/genres/${this.props.genre.id}/random-artist`
      ).then(
        res => res.json()
      ).then(
        result => this.queueArtist(result.ArtistId)
      ).catch(console.log);
    });
  }

  genreChanged = () => {
    this.randomizeArtist();
  }

  queueArtist = (spotifyArtistId) => {
    this.setState({
      spotifyArtistId: spotifyArtistId
    }, () => {
      fetch(
        `https://open.spotify.com/oembed?url=https%3A%2F%2Fopen.spotify.com%2Fartist%2F${spotifyArtistId}`
      ).then(
        res => res.json()
      ).then(
        result => this.setState({
          thumbnailUrl: result.thumbnail_url
        })
      ).catch(console.log);
    });
  }

  thumbnailLoaded = () => {
    const colors = getColors(this.thumnailRef.current, this.canvasRef.current);
    this.setState({
      colorScheme: this.pickColor(colors),
      loading: false,
      firstArtistLoaded: true
    });
  }

  pickColor = (colors) => {
    for(let i = 0; i < colors.length; i++) {
      const color = colors[i];
      if (color.luminance <= 100) {
        return color;
      }
    }
    return colors[Math.floor(colors.length / 2)]
  }

  introShouldDisplay = () => {
    return this.state.firstArtistLoaded === false;
  }

  render() {
    const imageLoader = this.state.thumbnailUrl ? (
      <div className="Jukebox__image-loader">
        <img
        className="Jukebox__thumbnail"
        src={this.state.thumbnailUrl}
        onLoad={this.thumbnailLoaded}
        ref={this.thumnailRef}
        data-spotify-artist-id={this.state.spotifyArtistId}
      />
      <canvas
        className="Jukebox__canvas"
        id="canvas"
        ref={this.canvasRef}
      >
      </canvas>
    </div>
    ) : null;

    if (this.introShouldDisplay()) {
      return (
        <div>
          <Intro
            loading={ this.state.loading }
          />
          { imageLoader }
        </div>
      );
    }
    return (
      <div
        className="Jukebox-container"
        style={{
          backgroundImage: `url(${this.state.thumbnailUrl})`
        }}
      >
        <div
          className="Jukebox"
          style={{
            backgroundImage: `linear-gradient(to bottom, ${this.state.colorScheme.hex}, ${this.state.colorScheme.complementaryHex})`
          }}
        >
          <div className="Jukebox__genre">
            { this.props.genre.name }
          </div>
          <SpotifyPlayer
            artistId={this.state.spotifyArtistId}
            explorerDimensions={this.props.explorerDimensions}
            loading={ this.state.loading }
          />
          <button
            className="Jukebox__random-artist"
            onClick={this.randomizeArtist}
            style={{
              backgroundColor: this.state.colorScheme.hex
            }}
            disabled={ this.state.loading }
          >
            random artist
          </button>
          { imageLoader }
        </div>
      </div>
    );
  }
}

export default Jukebox;