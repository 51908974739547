import React from "react";

import "./ZoomControls.css";
import zoomIn from "./images/zoom-in.svg";
import zoomOut from "./images/zoom-out.svg";

class ZoomControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onZoomOutTouchEnd = (e) => {
    this.props.zoomOut();
  }

  onZoomInTouchEnd = (e) => {
    this.props.zoomIn();
  }

  render() {
    return (
      <div className="GenreMap__zoomcontrols">
        <div
          className="GenreMap__zoomcontrol"
          onClick={this.props.zoomIn}
          onTouchEnd={this.onZoomInTouchEnd}
        >
          <img
            src={zoomIn}
          />
        </div>
        <div
          className="GenreMap__zoomcontrol"
          onClick={this.props.zoomOut}
          onTouchEnd={this.onZoomOutTouchEnd}
        >
          <img
            src={zoomOut}
          />
        </div>
      </div>
    );
  }
}

export default ZoomControls;