import React from "react";

import "./GenreLayout.css";
import genreGraph from "./data/genreGraph.json";
import throttle from "lodash.throttle";

class GenreLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draggingOrScaling: false,
      clicking: false,
      displayedGenres: {}
    };
    this.genres = genreGraph.sort((a,b) => b.artistCount - a.artistCount)
    this.updateDisplayedGenres = throttle(this._updateDisplayedGenres, 300);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.zoom != this.props.zoom) {
      this.updateDisplayedGenres();
    }
  }

  componentDidMount() {
    this.updateDisplayedGenres();
  }

  _updateDisplayedGenres = () => {
    this.setState({
      displayedGenres: this.calcDisplayedGenres()
    })
  }

  calcDisplayedGenres() {
    const displayedGenres = {}

    const totalGenres = this.genres.length;
    const zoomWhereAllGenresDisplayed = 4.5;
    let numGenresToDisplay = Math.floor(totalGenres * this.props.zoom / zoomWhereAllGenresDisplayed);
    numGenresToDisplay = Math.min(totalGenres, numGenresToDisplay);
    const genresToDisplay = this.genres.slice(0, numGenresToDisplay);
    genresToDisplay.forEach(genre => {
      displayedGenres[genre.genreId] = genre;
    })

    return displayedGenres;
  }

  shouldDisplay(genre) {
    return this.state.displayedGenres[genre.genreId] != null;
  }

  calcDistance(genre1, genre2, mapWidth, mapHeight) {
    const x1 = genre1.x * mapWidth;
    const y1 = genre1.y * mapHeight;
    const x2 = genre2.x * mapWidth;
    const y2 = genre2.y * mapHeight;
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
  }

  getPosition(genre) {
    const { dimensions } = this.props;
    const { width, height } = dimensions;

    if(width && height) {
      return {
        left: `${genre.x * width}px`,
        top: `${genre.y * height}px`
      }
    }
    return { left: 0, top: 0 }
  }

  calcAttrs(genre, display) {
    const style = this.getPosition(genre)
    let className = "GenreLayout__genre";
    if (this.props.genre && this.props.genre.id === genre.genreId) {
      className += " GenreLayout__genre--selected";
    } else if (display) {
      className += " GenreLayout__genre--displayed";
    } else {
      className += " GenreLayout__genre--hidden";
    }
    if (this.props.mapIsDraggingOrScaling) {
      style.cursor = "move"
    }
    return {
      style: style,
      className: className
    }
  }

  containsGenre(genre, genres) {
    for (let i = 0; i < genres.length; i++) {
      if (genres[i].genreId === genre.genreId) {
        return true;
      }
    }
    return false;
  }

  genreMouseDownHandler = (e) => {
    this.setState({
      clicking: true
    })
  }

  genreMouseUpHandler = (e) => {
    if (this.state.clicking && !this.state.draggingOrScaling) {
      this.genreClicked(e);
    }
    this.setState({
      clicking: false,
      draggingOrScaling: false
    })
  }

  genreMouseMoveHandler = (e) => {
    if (!this.state.clicking) {
      return;
    }
    if (this.props.mapIsDraggingOrScaling) {
      this.setState({
        draggingOrScaling: true
      })
    }
  }

  genreClicked = (e) => {
    const id = e.target.getAttribute("data-genre-id");
    const name = e.target.getAttribute("data-genre-name");
    this.props.genreSelected({
      id: id,
      name: name
    });
  };

  render() {
    const genreElements = this.genres.map((genre) => {
      const display = this.shouldDisplay(genre);
      const attrs = this.calcAttrs(genre, display); 

      return (
        <div
          className={attrs.className}
          key={genre.genreId}
          style={attrs.style}
          data-genre-id={genre.genreId}
          data-genre-name={genre.name}
          onMouseDown={this.genreMouseDownHandler}
          onMouseUp={this.genreMouseUpHandler}
          onMouseMove={this.genreMouseMoveHandler}
          onTouchStart={this.genreMouseDownHandler}
          onTouchEnd={this.genreMouseUpHandler}
          onTouchMove={this.genreMouseMoveHandler}
        >
          { genre.name.replace(/\s+/g, "\n") }
        </div>
      )
    })

    return (
      <div className="GenreLayout">
        {genreElements}
      </div>
    );
  }
}

export default GenreLayout;